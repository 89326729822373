import axios from 'axios'
import Long from 'long'
import qs from 'qs'
import React, { ReactElement, useCallback } from 'react'
import toast from 'react-hot-toast'
import { Updater, useImmer } from 'use-immer'

import { useCurrentUserContext } from '../../context/CurrentUserContext'
import { useSegmentTrack } from '../../hooks/analytics/useSegmentAnalytics'
import { useTrackClick } from '../../hooks/analytics/useTrackClick'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import IconAlert from '../../icons/IconAlert.svg'
import IconEmail from '../../icons/IconEmail.svg'
import IconFacebook from '../../icons/IconFacebook.svg'
import IconInstagram from '../../icons/IconInstagram.svg'
import IconLink from '../../icons/IconLink.svg'
import IconMessenger from '../../icons/IconMessenger.svg'
import IconSMS from '../../icons/IconSMS.svg'
import IconTwitter from '../../icons/IconTwitter.svg'
import IconWhatsApp from '../../icons/IconWhatsApp.svg'
import { commands, globals } from '../../lib/messages/protobuf'
import { errorToHelpfulMessage } from '../../models/APIErrorResponse'
import { IntoUrl } from '../../models/IntoUrl'
import IntoAPI from '../../services/IntoAPI'
import MixMix from '../../services/MixMix'
import { useTrackExternalUrlShare } from '../../tracking/events/useTrackExternalUrlShare'
import { IconBookmark } from '../IconBookmark'
import LoginButton from '../login/LoginButton'
import { useModalActions } from '../Modal'
import { UrlReportModal } from './UrlReportModal'

import TrackExternalUrlPrivateShareEvent = commands.TrackExternalUrlPrivateShareEvent
import ShareType = globals.ShareType

export interface ShareMedium {
  name: string
  color: string
  mobileOnly?: boolean
  shareType: ShareType
  getShareHref: (href: string, url?: IntoUrl) => string
}

const shareMediums: Array<ShareMedium> = [
  {
    name: 'SMS',
    color: 'bg-[#00FF00]',
    mobileOnly: true,
    shareType: ShareType.SMS_SHARE,
    getShareHref: (href, url) =>
      'sms:?&' +
      qs.stringify({
        subject: `Mix • ${url?.title}`,
        body: 'Check this out on Mix: ' + href,
      }),
  },
  {
    name: 'Facebook',
    color: 'bg-[#3b5998]',
    shareType: ShareType.FB_SHARE,
    getShareHref: href =>
      ' https://www.facebook.com/sharer/sharer.php?app_id=1633239056950945&display=popup&sdk=joey&u=' +
      encodeURIComponent(href),
  },
  {
    name: 'Twitter',
    color: 'bg-[#00acee]',
    shareType: ShareType.TWITTER_SHARE,
    getShareHref: (href, url) =>
      'https://twitter.com/intent/tweet?' +
      qs.stringify({
        text: 'Check this out on Mix',
        related: 'getmixapp',
        via: 'getmixapp',
        url: href,
        hashtags: url?.nodes
          // eslint-disable-next-line no-magic-numbers
          .slice(0, 3)
          .map(node => node.name.replace('-', ''))
          .join(','),
      }),
  },
  {
    name: 'WhatsApp',
    color: 'bg-[#25D366]',
    shareType: ShareType.WHATSAPP_SHARE,
    getShareHref: href => 'https://wa.me?text=' + encodeURIComponent('Check this out: ' + href),
  },
  {
    name: 'Messenger',
    color: 'bg-[#0099FF]',
    mobileOnly: true,
    shareType: ShareType.FB_MESSENGER_SHARE,
    getShareHref: href => 'fb-messenger://share?link=' + encodeURIComponent(href),
  },
  {
    name: 'Instagram',
    color: 'bg-[#C13584]',
    mobileOnly: true,
    shareType: ShareType.INSTAGRAM_SHARE,
    getShareHref: href => 'instagram://sharesheet?text=' + encodeURIComponent('Check this out: ' + href),
  },
  {
    name: 'Email',
    color: 'bg-[#0099FF]',
    shareType: ShareType.EMAIL_SHARE,
    getShareHref: (href, url) =>
      'mailto:?' +
      qs.stringify({
        subject: `Check this out on Mix`,
        body: `${url?.title}: ${href}`,
      }),
  },
]

const IconMap: { [key: string]: React.FC } = {
  IconEmail,
  IconFacebook,
  IconInstagram,
  IconMessenger,
  IconSMS,
  IconTwitter,
  IconWhatsApp,
}

const CopyButton = ({ url, onClose }: { url: IntoUrl; onClose: () => void }) => {
  const { currentUserID } = useCurrentUserContext()
  const onCopyClick = useCallback(() => {
    navigator.clipboard
      .writeText(url.meta.shareURL ?? '')
      .then(() => {
        toast.success('Copied link to clipboard')
      })
      .catch(() => toast.error('Failed to copy link to clipboard'))
    if (currentUserID) {
      void axios
        .request(
          MixMix.commands.trackExternalUrlShare(
            new TrackExternalUrlPrivateShareEvent({
              senderUserId: currentUserID,
              urlId: Long.fromString(url.url_id),
              shareType: ShareType.COPY_LINK,
            })
          )
        )
        .catch(() => {})
    }
    onClose()
  }, [url, onClose, currentUserID])

  return (
    <button onClick={onCopyClick}>
      <ShareButtonInner label="Copy Link">
        <IconLink />
      </ShareButtonInner>
    </button>
  )
}
const SaveButton = ({ url, setUrl }: { url: IntoUrl; setUrl: Updater<IntoUrl> }) => {
  const trackClick = useTrackClick()
  const toggleSaved = useCallback(async () => {
    try {
      setUrl(draft => {
        draft.meta.isSaved = !draft.meta.isSaved
      })
      let endpoint
      if (url.meta.isSaved) {
        endpoint = IntoAPI.url.deleteSaved
        trackClick('urlRemoveSaved')
      } else {
        endpoint = IntoAPI.url.save
        trackClick('urlSave')
      }
      await axios.request(endpoint({ url_id: url.url_id }))
    } catch (e) {
      setUrl(draft => {
        draft.meta.isSaved = !draft.meta.isSaved
      })
      toast.error(errorToHelpfulMessage(e))
    }
  }, [setUrl, url.meta.isSaved, url.url_id, trackClick])

  return (
    <LoginButton onClickIfLoggedIn={toggleSaved}>
      <ShareButtonInner label={url.meta.isSaved === true ? 'Saved' : 'Save'}>
        <IconBookmark isSelected={url.meta.isSaved === true} />
      </ShareButtonInner>
    </LoginButton>
  )
}
const ReportButton = ({ url }: { url: IntoUrl }) => {
  const { isVisible, toggleVisibility } = useModalActions()
  const { currentUserID } = useCurrentUserContext()
  const trackClick = useTrackClick()

  if (!currentUserID) return <></>
  return (
    <>
      <button
        onClick={() => {
          trackClick('report')
          toggleVisibility()
        }}
      >
        <ShareButtonInner label="Report">
          <IconAlert />
        </ShareButtonInner>
      </button>
      <UrlReportModal url={url} isVisible={isVisible} onClose={toggleVisibility} />
    </>
  )
}

interface SocialShareButtonsProps {
  url: IntoUrl
  onClose: () => void
}

interface ShareButtonInnerProps {
  children: ReactElement
  label: string
  bgColor?: string
}

const ShareButtonInner = ({ children, label, bgColor }: ShareButtonInnerProps) => (
  <div className="flex w-20 shrink-0 flex-col items-center whitespace-nowrap">
    <div
      className={`flex size-11 items-center justify-center rounded-full ${bgColor ?? 'bg-primary/90'} hover:scale-105`}
    >
      {React.cloneElement(children, { className: `h-6 w-6 text-white` })}
    </div>
    <div className="mt-2 text-xs">{label}</div>
  </div>
)

const SocialShareButton = ({ url, medium, onClose }: { url: IntoUrl; medium: ShareMedium; onClose: () => void }) => {
  const trackExternalShare = useTrackExternalUrlShare(url, medium)
  const SocialMediumIcon = IconMap[`Icon${medium.name}`]
  const track = useSegmentTrack()

  const onShare = useCallback(() => {
    const urlHref = url.meta.shareURL ?? ''
    const shareHref = medium.getShareHref(urlHref, url)
    window.open(shareHref)
    trackExternalShare()
    track('externalShare', { shareType: medium.shareType })
    onClose()
  }, [url, medium, onClose, trackExternalShare, track])

  return (
    <button key={medium.name} onClick={onShare}>
      <ShareButtonInner bgColor={medium.color} label={medium.name}>
        <SocialMediumIcon />
      </ShareButtonInner>
    </button>
  )
}

const ExternalShareButtons = ({ url: urlDetails, onClose }: SocialShareButtonsProps) => {
  const [url, setUrl] = useImmer(urlDetails)
  const isDesktop = useIsDesktop()
  const filteredMediums = isDesktop ? shareMediums.filter(medium => !medium.mobileOnly) : shareMediums

  return (
    <div className="flex overflow-x-scroll px-4 py-2 scrollbar-hide">
      {filteredMediums.map(medium => (
        <SocialShareButton key={medium.name} url={url} medium={medium} onClose={onClose} />
      ))}
      <CopyButton url={url} onClose={onClose} />
      <SaveButton url={url} setUrl={setUrl} />
      <ReportButton url={url} />
    </div>
  )
}
export default ExternalShareButtons
