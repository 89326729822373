import Link from 'next/link'
import { useRouter } from 'next/router'
import qs from 'qs'
import { FormEventHandler, ForwardedRef, ReactNode, forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import toast from 'react-hot-toast'

import { useTrackClick } from '../../hooks/analytics/useTrackClick'
import IconApple from '../../icons/IconApple.svg'
import IconFacebook from '../../icons/IconAuthFacebook.svg'
import IconTwitter from '../../icons/IconAuthTwitter.svg'
import IconGoogle from '../../icons/IconGoogle.svg'
import IconPlus from '../../icons/IconPlus.svg'
import IconSpinner from '../../icons/IconSpinner.svg'
import { GoogleRecaptcha } from '../../lib/GoogleRecaptcha'
import logger from '../../lib/logger'
import { isServer } from '../../lib/runtime-environment'
import runtimeConfig from '../../lib/RuntimeConfig'
import UrlHelper from '../../lib/UrlHelper'
import { setIsLoginModalOpen } from '../../redux/slices/modals/loginModalSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store/store'
import Modal from '../Modal'

interface LoginModalCopy {
  title: string
  subtitle: string
}

// Variations of copy on the login modal based on the way we show them
const loginCopy: Record<'default' | 'add' | 'login' | 'captcha' | 'personalize', LoginModalCopy> = {
  // This is the default copy.
  default: {
    title: 'Join Mix',
    subtitle: 'Your AI powered personal feed of images, videos, gifs and more.',
  },
  // Shown when an unauthenticated user visits the /add page
  add: {
    title: 'Welcome to Mix',
    subtitle: 'Please sign up or login to submit the link.',
  },
  // Shown when a user clicks on a "Login" button somewhere (not the Join or Sign up).
  login: {
    title: 'Welcome to Mix',
    subtitle: 'Please login to continue.',
  },
  // Shown when we present captcha box during the sign-up.
  captcha: {
    title: 'Before we continue',
    subtitle: 'Please verify you are a human',
  },
  // Shown when we automatically trigger the login modal on scroll
  personalize: {
    title: 'Join Mix to personalize your experience',
    subtitle: 'Your Al-powered personal feed of interesting things for you.',
  },
}

type AuthLinkName = 'facebookAuth' | 'twitterAuth' | 'googleAuth' | 'appleAuth'

const AuthLink = ({ name, icon, returnUrl }: { name: string; icon: ReactNode; returnUrl?: string }) => {
  const reCaptchaV3Key = runtimeConfig().publicRuntimeConfig.auth.reCaptchaV3Key
  const reCaptchaV2Key = runtimeConfig().publicRuntimeConfig.auth.reCaptchaV2Key
  const formRef = useRef<HTMLFormElement | null>(null)
  const recaptchaV2Ref = useRef<ReCAPTCHA>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const trackClick = useTrackClick()
  const authLinkName = `${name.toLowerCase()}Auth` as AuthLinkName
  const { asPath } = useRouter()
  const currentPath = useMemo(() => {
    if (isServer()) {
      return asPath
    }
    return window.location.href.replace(window.location.origin, '')
  }, [asPath])

  const href = useMemo(() => {
    const returnPath = `${UrlHelper.asRelativePath(returnUrl) ?? currentPath}`
    return `/auth/${name.toLowerCase()}${qs.stringify({ r: returnPath }, { addQueryPrefix: true })}`
  }, [currentPath, name, returnUrl])

  const submitWithTokens = useCallback(
    async (recaptchaV3Token: string, recaptchaV2Token?: string) => {
      if (!formRef.current) {
        setIsSubmitting(false)
        return
      }

      try {
        const inputV3 = document.createElement('input')
        inputV3.type = 'hidden'
        inputV3.name = 'recaptchaV3Token'
        inputV3.value = recaptchaV3Token
        formRef.current.appendChild(inputV3)

        if (recaptchaV2Token) {
          const inputV2 = document.createElement('input')
          inputV2.type = 'hidden'
          inputV2.name = 'recaptchaV2Token'
          inputV2.value = recaptchaV2Token
          formRef.current.appendChild(inputV2)
        }

        formRef.current.submit()
      } catch (error) {
        logger.error('Error on reCAPTCHA submit', error)
        toast.error('Sorry, something went wrong. Please try again.')
        setIsSubmitting(false)
      }
    },
    [formRef]
  )

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async event => {
      event.preventDefault()
      setIsSubmitting(true)

      try {
        // Check if grecaptcha is loaded
        if (typeof window.grecaptcha === 'undefined') {
          throw new Error('reCAPTCHA not loaded')
        }
        // Attempt reCAPTCHA v3 first.
        const recaptchaV3Token = await window.grecaptcha.execute(reCaptchaV3Key, { action: 'submit' })
        await submitWithTokens(recaptchaV3Token)
      } catch (error) {
        logger.warn('Error on reCAPTCHA v3', error)
        // Fall back to reCAPTCHA v2.
        recaptchaV2Ref.current?.execute()
      }
    },
    [reCaptchaV3Key, submitWithTokens]
  )

  const handleCaptchaV2Change = useCallback(
    async (token: string | null) => {
      if (token && !isSubmitting) {
        setIsSubmitting(true)
        await submitWithTokens('', token)
      } else {
        setIsSubmitting(false)
      }
    },
    [submitWithTokens, isSubmitting]
  )

  return (
    <form ref={formRef} action={href} method="post" onSubmit={handleFormSubmit}>
      <button
        type="submit"
        disabled={isSubmitting}
        onClick={() => trackClick(authLinkName)}
        className="btn btn-square flex w-full items-center space-x-4 rounded-lg border-2 p-4 hover:border-accent focus:outline-none"
      >
        <span className="w-6"> {icon}</span>
        <span className="w-full text-left text-sm">{name}</span>
        {isSubmitting && <IconSpinner className="h-6" />}
      </button>
      <ReCAPTCHA ref={recaptchaV2Ref} sitekey={reCaptchaV2Key} onChange={handleCaptchaV2Change} size="invisible" />
    </form>
  )
}

const AuthLinks = ({ returnUrl }: { returnUrl?: string }) => {
  return (
    <div className="w-full flex-col space-y-2">
      <AuthLink name="Facebook" icon={<IconFacebook />} {...{ returnUrl }} />
      <AuthLink name="Twitter" icon={<IconTwitter />} {...{ returnUrl }} />
      <AuthLink name="Google" icon={<IconGoogle />} {...{ returnUrl }} />
      <AuthLink name="Apple" icon={<IconApple />} {...{ returnUrl }} />
    </div>
  )
}
const LoginModalInner = (_: unknown, ref: ForwardedRef<HTMLElement>) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const trackClick = useTrackClick()

  const { isLoginModalOpen, returnPathOnClose, returnUrl, copyVariant } = useAppSelector(state => state.loginModal)
  const onClose = () => {
    dispatch(setIsLoginModalOpen(false))
    if (returnPathOnClose) router.replace('/', returnPathOnClose, { shallow: true }).catch(() => {})
  }
  const copy = loginCopy[copyVariant]

  return (
    <>
      <GoogleRecaptcha />
      <Modal isVisible={isLoginModalOpen} onClose={onClose} ref={ref}>
        <div className="relative w-full rounded-lg bg-menu p-10 pt-12 text-menu selection:bg-accent/50 sm:w-[30rem]">
          <button className="absolute right-6 top-6" tabIndex={-1} onClick={onClose}>
            <IconPlus className="rotate-45 text-[#999999]" />
          </button>
          <div className="space-y-8">
            <div className="space-y-6">
              <div className="text-3xl font-bold">{copy.title}</div>
              <div className="text-xl">{copy.subtitle}</div>
            </div>
            <AuthLinks returnUrl={returnUrl} />
            <div className="text-sm leading-tight text-black/60">
              By continuing, you are setting up a Mix account and agree to our{' '}
              <Link href="/tos" onClick={() => trackClick('termsOfServices')} className="text-accent">
                Terms of Services
              </Link>{' '}
              and{' '}
              <Link href="/privacy" onClick={() => trackClick('privacyPolicy')} className="text-accent">
                Privacy Policy.
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export const LoginModal = forwardRef(LoginModalInner)
